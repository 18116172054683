/*
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2022-06-14 14:04:49
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-15 11:33:50
 */
import http from '@/utils/http';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary';
/**
 * 历史记录 分页查询
 * @returns
 */
export function history(params) {
  return http.get(
    '/cocc-quotalibrary-resource/1/qy/stl/bill/de/history',
    params
  );
}

/**
 * 历史记录 替换功能
 * @returns
 */
export function rollback(params) {
  return http.post(
    '/cocc-quotalibrary-resource/1/qy/stl/bill/de/history/rollback',
    params
  );
}

/**
 * 使用清单code、项目名称、单位3要素查询方案数据
 * @returns
 */
export function schemes(params) {
  return http.get('/cocc-quotalibrary-resource/1/qyStlBillDe/schemes', params);
}

/**
 * 编辑 定额
 * @returns
 */
export function editBillDe(params) {
  return http.put(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/batch/bill/de',
    params
  );
}

/**
 * 删除单个方案
 * @returns
 */
export function deleteBillDe(billSequenceNbr) {
  return http.delete(
    `/cocc-quotalibrary-resource/1/qyStlBillDe/bill-de/${billSequenceNbr}`
  );
}

/**
 * 根据四要素判断当前企业下是否存在相同清单
 * @param params 
 * @returns 
 */
export function queryAlikeQdMajor(params) {
  return http.post('/cocc-quotalibrary-resource/1/qyStlBillDe/queryAlikeQdMajor', params)
}

/**
 * 综合单价趋势图
 * @param params 
 * @returns 
 */
export function tendencyChart(params) {
  return http.post<Record<string, {
    billPrice: number; 
    billUpdateTime: string; 
    projectAttr: string
  }[]>>('/cocc-quotalibrary-resource/1/qy/stl/bill/de/history/bill/tendency-chart', params)
}

/**
 * 详情计算修改的清单定额
 * @param params 
 * @returns 
 */
export function updateQdAgainMath(params) {
  return http.post<QyStlBillDeType | null>('/cocc-quotalibrary-resource/1/qyStlBillDe/scheme/updateQdAgainMath', params)
}

/**
 * 重新计算所有清单定额
 */

export function againMathQdDePrice(qyStlBillDeList) {
  return http.post<QyStlBillDeType[]>('/cocc-quotalibrary-resource/1/qyStlBillDe/againMathQdDePrice', {qyStlBillDeList})
}