var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-quota"},[_c('customDrawer',{staticClass:"common-dialog",attrs:{"placement":"right","width":"80%","visible":_vm.visible,"closable":false},on:{"close":_vm.handleCancel}},[_c('template',{slot:"title"},[_c('div',{staticClass:"drawer-title"},[_c('a-icon',{staticClass:"icon",attrs:{"type":"left"},on:{"click":_vm.handleCancel}}),_vm._v("定额编辑 ")],1)]),_c('div',{staticClass:"options"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openCost}},[_vm._v("添加定额")]),_c('a-button',{staticClass:"delete-btn",on:{"click":_vm.removeCost}},[_vm._v("移除")]),(_vm.tableData)?_c('span',{staticClass:"price"},[_vm._v("综合单价(元)："),_c('b',[_vm._v(_vm._s(_vm.tableData.price))])]):_vm._e()],1),_c('div',{staticClass:"drawer-box"},[_c('combination-table',{ref:"combinationTable",staticClass:"combination-table scrollbar",attrs:{"detailedListBind":{
          actionList: [],
          tableData: [_vm.tableData],
          showPagination: false,
        },"detailedListFunction":{ editHandler: _vm.editHandler },"quotaBind":{
          actionList: [],
          rowSelection: _vm.rowSelection,
          fieldSlots: _vm.quotaFiledSlot,
          editFields: _vm.quotaEditField,
        },"quotaFunction":{
          editHandler: _vm.quotaEditHandler,
        }},scopedSlots:_vm._u([_vm._l((_vm.quotaFiledSlot),function(item,key){return {key:item,fn:function(ref){
        var record = ref.record;
        var text = ref.text;
        var index = ref.index;
        var parentIndex = ref.parentIndex;
return [_c('div',{key:key,staticClass:"link",on:{"click":function($event){return _vm.viewRCJList(record, index, parentIndex)}}},[_vm._v(" "+_vm._s(text)+" ")])]}}})],null,true)}),_c('div',{staticClass:"down-content"},[_c('quotaDetail',{ref:"quotaDetail",attrs:{"adsQycbRcjModelList":_vm.currentRecordInfo.record &&
            _vm.currentRecordInfo.record.adsQycbRcjModelList,"qyDePriceConstituteList":_vm.currentRecordInfo.record &&
            _vm.currentRecordInfo.record.qyDePriceConstituteList},on:{"apply":_vm.materialApply}})],1)],1),_c('div',{staticClass:"btn-group"},[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"disabled":(_vm.tableData && !_vm.tableData.deList.length) || !!_vm.failInfo || _vm.isRequest,"type":"primary"},on:{"click":_vm.editBillDe}},[_vm._v("保存")])],1)],2),_c('cost-combination',{attrs:{"visible":_vm.costVisible,"actionRecord":_vm.currentData,"addCost":false},on:{"update:visible":function($event){_vm.costVisible=$event},"saveCallback":_vm.saveCallback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }