<!--
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2022-06-13 14:20:18
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-23 19:10:16
-->
<template>
  <div class="edit-quota">
    <customDrawer
      placement="right"
      width="80%"
      :visible="visible"
      @close="handleCancel"
      :closable="false"
      class="common-dialog"
    >
      <template slot="title">
        <div class="drawer-title">
          <a-icon type="left" class="icon" @click="handleCancel" />定额编辑
        </div>
      </template>
      <div class="options">
        <a-button type="primary" @click="openCost">添加定额</a-button>
        <a-button class="delete-btn" @click="removeCost">移除</a-button>
        <span class="price" v-if="tableData"
          >综合单价(元)：<b>{{ tableData.price }}</b></span
        >
      </div>
      <div class="drawer-box">
        <combination-table
          class="combination-table scrollbar"
          ref="combinationTable"
          :detailedListBind="{
            actionList: [],
            tableData: [tableData],
            showPagination: false,
          }"
          :detailedListFunction="{ editHandler }"
          :quotaBind="{
            actionList: [],
            rowSelection,
            fieldSlots: quotaFiledSlot,
            editFields: quotaEditField,
          }"
          :quotaFunction="{
            editHandler: quotaEditHandler,
          }"
        >
          <template
            #[item]="{ record, text, index, parentIndex }"
            v-for="(item, key) in quotaFiledSlot"
          >
            <div
              class="link"
              @click="viewRCJList(record, index, parentIndex)"
              :key="key"
            >
              {{ text }}
            </div>
          </template>
        </combination-table>
        <div class="down-content">
          <quotaDetail
            ref="quotaDetail"
            @apply="materialApply"
            :adsQycbRcjModelList="
              currentRecordInfo.record &&
              currentRecordInfo.record.adsQycbRcjModelList
            "
            :qyDePriceConstituteList="
              currentRecordInfo.record &&
              currentRecordInfo.record.qyDePriceConstituteList
            "
          ></quotaDetail>
        </div>
      </div>
      <div class="btn-group">
        <a-button @click="handleCancel">取消</a-button>
        <a-button
          :disabled="
            (tableData && !tableData.deList.length) || !!failInfo || isRequest
          "
          type="primary"
          @click="editBillDe"
          >保存</a-button
        >
      </div>
    </customDrawer>
    <cost-combination
      :visible.sync="costVisible"
      :actionRecord="currentData"
      :addCost="false"
      @saveCallback="saveCallback"
    ></cost-combination>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import CostCombination from '../costLibrary/combination';
import CombinationTable from '../components/combinationTable.vue';
import {
  editBillDe,
  queryAlikeQdMajor,
  updateQdAgainMath,
} from '@/api/costQuotaLibrary/detail';
import { getTotalPrice } from '../index.ts';
import quotaDetail from '../components/quotaDetail.vue';

export default Vue.extend({
  name: 'editQuota',
  components: { CostCombination, CombinationTable, quotaDetail },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
    },
    actionRecord: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isRequest: false,
      costVisible: false,
      selectedRowKeys: [], // 选中的数据sequenceNbr
      originalData: [], // 一开始的原始数据
      failInfo: null,
      currentRecordInfo: { record: {} },
      quotaFiledSlot: ['bdCode', 'bdName'],
      quotaRef: null,
      tableData: null,
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.selectedRowKeys = [];
        this.originalData = _.cloneDeep(this.currentData.deList);
        this.tableData = this.currentData;
        this.getDeList();
        this.$nextTick(() => {
          this.$refs.combinationTable.setExpandedRowKeys([
            this.tableData.sequenceNbr,
          ]);
        });
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
        },
      };
    },
    quotaEditField() {
      const quotaRef = this.quotaRef;
      if (quotaRef && quotaRef.isDataChange) return [];
      return ['quantity'];
    },
  },
  methods: {
    materialApply() {
      // 应用
      const { index, record, parentIndex } = this.currentRecordInfo;
      this.tableData.deList?.splice(index, 1, record);
      this.againCalc(0);
    },
    againCalc(qdIndex = -1, deIndex = -1) {
      let params = {
        qyStlBillDeList: [this.tableData],
      };
      if (deIndex >= 0) params.deIndex = deIndex;
      if (qdIndex >= 0) params.qdIndex = qdIndex;
      this.isRequest = true;
      updateQdAgainMath(params)
        .then(({ status, result }) => {
          if (status === 200 && result) {
            this.tableData = result;
            const { index, parentIndex, record } = this.currentRecordInfo;
            if (!parentIndex && parentIndex !== 0) return;
            this.$set(
              this.currentRecordInfo,
              'record',
              this.tableData?.deList[index]
            );
          }
        })
        .finally(() => {
          this.isRequest = false;
        });
    },
    /**
     * 定额材料是否有修改，未应用
     */
    isMaterialChange() {
      const quotaRef = this.quotaRef;
      if (quotaRef && quotaRef.applyTipHandler()) {
        // 数值有变动为保存应用
        this.$message.warning('数据有修改请点击应用');
        return true;
      }
      return false;
    },
    viewRCJList(record, index, parentIndex) {
      record.adsQycbRcjModelList = (record.adsQycbRcjModelList || []).map(
        (item) => {
          item.sequenceNbr = item.id + new Date().getTime().toString(6);
          return item;
        }
      );
      this.$set(this.currentRecordInfo, 'record', record);
      this.$set(this.currentRecordInfo, 'index', index);
      this.$set(this.currentRecordInfo, 'parentIndex', parentIndex);
      this.$nextTick(() => {
        this.quotaRef = this.$refs.quotaDetail;
      });
    },
    handleCancel() {
      this.selectedRowKeys = [];
      this.getDeList();
      this.$emit('update:visible', false);
    },
    openCost() {
      if (this.isMaterialChange()) return;
      this.costVisible = true;
    },
    editHandler({ record, key, index }) {
      const { bdCode, bdName, projectAttr, unit, agencyCode, orgProjectAttr } =
        record;
      if (projectAttr === orgProjectAttr) return;
      record.orgProjectAttr = record.projectAttr;
      this.failInfo = null;
      queryAlikeQdMajor({ bdCode, bdName, projectAttr, unit, agencyCode })
        .then(({ status, result }) => {
          if (status === 200 && result) {
            this.failInfo = result;
            this.$message.warning(
              `当前项目清单已存在，在${Object.keys(result)[0]}专业${
                Object.values(result)[0]
              }分类下`
            );
          }
        })
        .finally(() => {
          this.tableData = record;
        });
    },
    quotaEditHandler({
      record,
      key,
      index,
      parentIndex,
      parentRecord,
      eventName,
    }) {
      const deList = this.tableData.deList || [];
      deList.splice(index, 1, record);
      this.againCalc(parentIndex, index);
    },
    // 编辑页面保存
    editBillDe() {
      if (this.isMaterialChange()) return;
      if (this.tableData.projectAttr === '') {
        this.$message.warning('项目特征不能为空');
        return;
      }
      const deList = this.tableData.deList;
      for (let k = 0; k < deList.length; k++) {
        const deItem = deList[k];
        if (deItem.isError) {
          this.$message.warning('请检查数据是否正确');
          return;
        }
      }
      editBillDe(this.tableData).then((res) => {
        if (res.status === 200 && res.result) {
          this.$message.success('保存成功');
          this.handleCancel();
          this.$emit('refreshList');
        }
      });
    },
    // 移除功能
    removeCost() {
      if (!this.selectedRowKeys.length) {
        return this.$message.error('请先选择要移除的定额');
      }
      if (this.isMaterialChange()) return;
      this.tableData.deList = this.tableData.deList.filter((item) => {
        return !this.selectedRowKeys.includes(item.sequenceNbr);
      });
      this.selectedRowKeys = [];
      this.againCalc(0);
    },
    getDeList() {
      this.tableData.deList = this.originalData.filter((item) => {
        return !this.selectedRowKeys.includes(item.sequenceNbr);
      });
    },
    saveCallback(list) {
      if (list.length > 0) {
        list.forEach((item) => {
          this.tableData.deList.push(item);
        });
        this.againCalc(0);
        // this.currentData.deList.push(list)
      }
    },
  },
});
</script>

<style lang="less" scoped>
.options {
  padding-bottom: 17px;
  button + button {
    margin-left: 14px;
  }
  .price {
    float: right;
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    b {
      color: #1890ff;
    }
  }
}
.drawer-box {
  height: calc(100vh - 174px);
  padding-bottom: 15px;
  .combination-table {
    height: 60%;
  }
  .down-content {
    height: 40%;
  }
}
.btn-group {
  text-align: center !important;
}
.link {
  color: #1e90ff;
  cursor: pointer;
}
</style>
